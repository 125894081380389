<template>
  <div class="hotline-list-container">

    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ t('Hotline') }}</b-card-title>
        <crud-buttons
          :name="t('Hotline')"
          :selected-rows="selectedRows"
          modal="modal-hotline"
          :show-export="false"
          :show-import="false"
          :enable-add="$can('create', 'hotline')"
          :hide-delete="!$can('delete', 'hotline')"
          @on-add="resetItem"
          @on-delete="onDelete"
        />
      </b-card-header>
      <b-card-body>

        <b-row class="mt-2">
          <b-col cols="12">
            <vue-good-table
              style-class="vgt-table striped bordered"
              mode="remote"
              :total-rows="totalRecords"
              :is-loading.sync="isLoading"
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: false,
              }"
              :select-options="{
                enabled: true,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              :pagination-options="{
                enabled: true,
                perPage: serverParams.perPage,
              }"
              @on-selected-rows-change="selectionChanged"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
            >
              <template slot="loadingContent">
                <b-spinner
                  label="Loading"
                  type="grow"
                />
              </template>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Code -->
                <span v-if="props.column.field === 'code'">
                  <b-link
                    v-b-modal.modal-hotline-detail
                    class="font-weight-bold"
                    @click="onViewDetailItem(props.row)"
                  >
                    {{ props.row.code }}
                  </b-link>
                </span>

                <!-- Column: Price -->
                <span v-else-if="props.column.field === 'name'">
                  <span>{{ props.row.name }}</span>
                  <span
                    v-if="props.row.apartment"
                    class="text-muted"
                  ><br>{{ t('Tòa nhà') }}: {{ props.row.apartment.name }}</span>
                  <span
                    v-if="props.row.room"
                    class="text-muted"
                  ><br>{{ t('Phòng') }}: {{ props.row.room.name }}</span>
                </span>

                <!-- Column: Price -->
                <span v-else-if="props.column.field === 'numberApartments'">
                  {{ Number(props.row.apartments.length).toLocaleString() }}
                </span>

                <!-- Column: Price -->
                <span v-else-if="props.column.field === 'price'">
                  {{ Number(props.row.price).toLocaleString() }}
                </span>

                <!-- Column: Price -->
                <span v-else-if="props.column.field === 'deposit'">
                  {{ Number(props.row.deposit).toLocaleString() }}
                </span>

                <!-- Column: Price -->
                <span v-else-if="props.column.field === 'status'">
                  <b-badge
                    class="font-small-1"
                    :variant="`light-${props.row.status.variant}`"
                  >{{ props.row.status.title }}</b-badge>
                </span>

                <span v-else-if="props.column.field === 'active'">
                  <b-badge
                    :variant="props.row.active ? 'light-primary' : 'light-danger'"
                    class="font-small-1"
                  >
                    <span>{{ props.row.active ? t("Có") : t("Không") }}</span>
                  </b-badge>
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <table-actions
                    :name="t('Hotline')"
                    modal="modal-hotline"
                    :hide-delete="!$can('delete', 'hotline')"
                    :hide-edit="!$can('update', 'hotline')"
                    @on-edit="onEditItem(props.row)"
                    @on-delete="onDelete(props.row)"
                  />
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ t('Hiển thị tối đa') }} </span>
                    <b-form-select
                      v-model="serverParams.perPage"
                      :options="['10', '20', '50', '100', '500']"
                      class="mx-1"
                      @input="
                        (value) => props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      {{ t('trên tổng số') }} {{ props.total }} {{ t('kết quả') }}
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="serverParams.perPage"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
              <div slot="emptystate">
                <div class="text-center text-muted">
                  {{ t('Không có bản ghi nào!') }}
                </div>
              </div>
            </vue-good-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <hotline-modal
      :item="item"
      @refetch-data="fetchData"
    />
    <!-- <hotline-detail-modal :hotline-id="item.id" /> -->
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BLink,
  BPagination,
  BFormSelect,
  BSpinner,
  BBadge,
} from 'bootstrap-vue';
import TableActions from '@/views/components/TableActions.vue';
import CrudButtons from '@/views/components/CrudButtons.vue';
import { VueGoodTable } from 'vue-good-table';
// eslint-disable-next-line import/no-cycle
import useHotlineList from './useHotlineList';
import HotlineModal from '../modal/HotlineModal.vue';
import HotlineDetailModal from '../detail/HotlineDetailModal.vue';

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BLink,
    BPagination,
    BBadge,
    BFormSelect,
    BSpinner,

    VueGoodTable,
    TableActions,
    CrudButtons,
    HotlineModal,
    HotlineDetailModal,
  },
  setup() {
    const {
      analytic,
      item,
      columns,
      rows,
      active,
      apartment,
      room,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,
      fetchData,
      fetchHotlines,
      deleteHotlines,
      resetItem,
      onEditItem,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      onViewDetailItem,
      t,
    } = useHotlineList();

    return {
      analytic,
      item,
      columns,
      rows,
      active,
      apartment,
      room,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,
      fetchData,
      fetchHotlines,
      deleteHotlines,
      resetItem,
      onEditItem,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      onViewDetailItem,
      t,
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    onDelete(hotline) {
      const deleteObjects = hotline && hotline.id > 0 ? [hotline] : this.selectedRows;
      this.deleteHotlines(deleteObjects);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.hotline-list-container {
  .vgt-table {
      thead {
        tr {
          th {
            min-width: 100px !important;

            &:first-child {
              min-width: 50px !important;
            }
            &:nth-child(2) {
               min-width: 80px !important;
            }
            &:nth-child(3) {
               min-width: 100px !important;
            }
            &:nth-child(4) {
               min-width: 240px !important;
            }
            &:nth-child(5) {
               min-width: 150px !important;
            }
            &:nth-child(6) {
               min-width: 150px !important;
            }
            &:nth-child(7) {
               min-width: 100px !important;
            }
            &:nth-child(8) {
               min-width: 100px !important;
            }
          }
        }
      }
    }
}
</style>
